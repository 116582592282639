import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
};


class MapContainer extends React.Component {

  constructor(props) {
    super(props);

    // this.state = {
    //   stores: [{lat: 47.49855629475769, lng: -122.14184416996333},
    //           {latitude: 47.359423, longitude: -122.021071},
    //           {latitude: 47.2052192687988, longitude: -121.988426208496},
    //           {latitude: 47.6307081, longitude: -122.1434325},
    //           {latitude: 47.3084488, longitude: -122.2140121},
    //           {latitude: 47.5524695, longitude: -122.0425407}]
    // }
    this.state = {
      stores: [{'name': '110 Cooperative 30 Cap', 'latitude': '33.870022', 'longitude': '-118.282474'} ,
      {'name': '110 Medical - 20 Cap', 'latitude': '33.975231', 'longitude': '-118.278577'} ,
      {'name': '123 Broadway', 'latitude': '32.643956', 'longitude': '-117.094173'} ,
      {'name': '20 COLLECTIVE', 'latitude': '33.960331', 'longitude': '-118.240531'} ,
      {'name': '24/7 Recording', 'latitude': '34.01904', 'longitude': '-118.193928'} ,
      {'name': '25 Cap HNH Holistic Natural Healing', 'latitude': '33.779593', 'longitude': '-118.261479'} ,
      {'name': '25 King', 'latitude': '33.97473', 'longitude': '-118.31075'} ,
      {'name': '310 CPTS Best', 'latitude': '33.897923', 'longitude': '-118.207855'} ,
      {'name': '40 Club', 'latitude': '34.05304', 'longitude': '-118.44006'} ,
      {'name': '419 CBD', 'latitude': '34.149248', 'longitude': '-118.120262'} ,
      {'name': '420 Exclusive', 'latitude': '33.971937', 'longitude': '-118.224968'} ,
      {'name': '5 Gramz Stop', 'latitude': '34.863555', 'longitude': '-118.163434'} ,
      {'name': '5 Leaves', 'latitude': '33.791101', 'longitude': '-118.249628'} ,
      {'name': '5G Bank', 'latitude': '34.005646', 'longitude': '-117.900446'} ,
      {'name': '710 DISTRICT', 'latitude': '36.757596', 'longitude': '-119.801217'} ,
      {'name': '805 Beach Breaks', 'latitude': '35.109301', 'longitude': '-120.618843'} ,
      {'name': '8th or Better - Lancaster', 'latitude': '34.705004', 'longitude': '-118.147945'} ,
      {'name': '8th Wellness', 'latitude': '34.052183', 'longitude': '-118.000433'} ,
      {'name': '99 High Tide - Malibu', 'latitude': '34.039469', 'longitude': '-118.671821'} ,
      {'name': 'A Shelf Above The Rest', 'latitude': '33.635078', 'longitude': '-117.924664'} ,
      {'name': 'Acres Cannabis | Las Vegas', 'latitude': '36.146187', 'longitude': '-115.166833'} ,
      {'name': 'Advanced Health Therapies Inc.', 'latitude': '34.118312', 'longitude': '-117.27893'} ,
      {'name': 'AHHSWEHO (Alternative Herbal Health Services)', 'latitude': '34.090602', 'longitude': '-118.360249'} ,
      {'name': 'Alhambra Collective 25 Cap', 'latitude': '34.092724', 'longitude': '-118.162434'} ,
      {'name': 'Alhambra Green Stop 25 CAP', 'latitude': '34.079177', 'longitude': '-118.16354'} ,
      {'name': 'All Greens Dispensary - Sun City AZ', 'latitude': '33.638493', 'longitude': '-112.275952'} ,
      {'name': 'Angels Kush - Korea Town', 'latitude': '34.056529', 'longitude': '-118.282585'} ,
      {'name': 'AOC American Original Collective', 'latitude': '34.8641446', 'longitude': '-118.1634075'} ,
      {'name': 'Apothecaric 30 Cap', 'latitude': '34.035959', 'longitude': '-118.260022'} ,
      {'name': 'Apothecary', 'latitude': '34.153227', 'longitude': '-118.468735'} ,
      {'name': 'Apothekare', 'latitude': '32.7774', 'longitude': '-117.1189'} ,
      {'name': 'Arizona Cannabis Society Inc', 'latitude': '33.5566011', 'longitude': '-112.3244326'} ,
      {'name': 'Arizona MMJ Trading Company', 'latitude': '32.9680729', 'longitude': '-111.5422'} ,
      {'name': 'Arizona Natural Concepts', 'latitude': '33.798474', 'longitude': '-112.059114'} ,
      {'name': 'Arizona Natural Remedies', 'latitude': '33.686627', 'longitude': '-112.108245'} ,
      {'name': 'Arizona Natural Selections of Peoria', 'latitude': '33.58041', 'longitude': '-112.257637'} ,
      {'name': 'Arizona Natural Selections of Scottsdale', 'latitude': '33.623289', 'longitude': '-111.921736'} ,
      {'name': 'Arizona Organix', 'latitude': '33.538342', 'longitude': '-112.172734'} ,
      {'name': 'Arrowhead Cannabis Church', 'latitude': '34.132483', 'longitude': '-117.289861'} ,
      {'name': 'Atlantic Wave', 'latitude': '34.012787', 'longitude': '-118.158932'} ,
      {'name': 'Atomic Budz Dispensary', 'latitude': '33.784476', 'longitude': '-116.468386'} ,
      {'name': 'ATRIUM', 'latitude': '34.169815', 'longitude': '-118.606036'} ,
      {'name': 'AV Remedies', 'latitude': '34.733613', 'longitude': '-118.140369'} ,
      {'name': 'Avalon Daily Deals - REAL 20 CAP', 'latitude': '33.950767', 'longitude': '-118.265'} ,
      {'name': 'Avenue CBD', 'latitude': '34.083802', 'longitude': '-118.354671'} ,
      {'name': 'Azusa Healing Church', 'latitude': '34.133841', 'longitude': '-117.923164'} ,
      {'name': 'Azusa Herbal Center', 'latitude': '34.133417', 'longitude': '-117.913337'} ,
      {'name': 'AZUSAS FINEST', 'latitude': '34.107456', 'longitude': '-117.919294'} ,
      {'name': 'Bakersfield&#x27;s Best 20 Cap', 'latitude': '35.353907', 'longitude': '-119.052066'} ,
      {'name': 'Balboa Ave Cooperative', 'latitude': '32.819382', 'longitude': '-117.137541'} ,
      {'name': 'Barber Shop Top Shelf', 'latitude': '34.088163', 'longitude': '-117.916226'} ,
      {'name': 'BARC - BEVERLY ALTERNATIVE RELIEF', 'latitude': '34.070764', 'longitude': '-118.375675'} ,
      {'name': 'BARE Dispensary', 'latitude': '33.904334', 'longitude': '-116.553288'} ,
      {'name': 'Base Line Greens', 'latitude': '34.121101', 'longitude': '-117.312086'} ,
      {'name': 'Baseline Buds 20 Cap', 'latitude': '34.121214', 'longitude': '-117.26783'} ,
      {'name': 'BEACH Center - South Bay', 'latitude': '33.858537', 'longitude': '-118.295374'} ,
      {'name': 'Beach Club 310', 'latitude': '33.95964', 'longitude': '-118.447241'} ,
      {'name': 'BEST OF BUDDHA', 'latitude': '33.972388', 'longitude': '-118.070748'} ,
      {'name': 'Big O Relief', 'latitude': '35.068858', 'longitude': '-118.174195'} ,
      {'name': 'Big Sur Cannabotanicals', 'latitude': '36.540957', 'longitude': '-121.906723'} ,
      {'name': 'Blackjack Collective', 'latitude': '36.150326', 'longitude': '-115.153551'} ,
      {'name': 'Blis - GRAND OPENING!!!', 'latitude': '34.04612', 'longitude': '-118.25584'} ,
      {'name': 'Bloom Copper Country', 'latitude': '32.617838', 'longitude': '-110.791297'} ,
      {'name': 'Bloom Peoria', 'latitude': '33.565869', 'longitude': '-112.255569'} ,
      {'name': 'Bloom Phoenix Dispensary', 'latitude': '33.447524', 'longitude': '-111.991963'} ,
      {'name': 'Bloom Sedona', 'latitude': '34.873627', 'longitude': '-111.761429'} ,
      {'name': 'Bloom Tucson', 'latitude': '32.291737', 'longitude': '-110.979578'} ,
      {'name': 'Bloom Valley 25 Cap', 'latitude': '34.257534', 'longitude': '-118.602615'} ,
      {'name': 'Blüm - Santa Ana', 'latitude': '33.705681', 'longitude': '-117.855686'} ,
      {'name': 'Blüm Las Vegas - Decatur', 'latitude': '36.123569', 'longitude': '-115.207501'} ,
      {'name': 'Blüm Las Vegas | Desert Inn', 'latitude': '36.130255', 'longitude': '-115.139546'} ,
      {'name': 'Bodhi Sattva', 'latitude': '34.0793075', 'longitude': '-118.3763794'} ,
      {'name': 'BOGO Clubhouse and CBD', 'latitude': '33.818519', 'longitude': '-117.881305'} ,
      {'name': 'Botanica - Tucson', 'latitude': '32.31976', 'longitude': '-111.04795'} ,
      {'name': 'Breaking Bud 20 Cap', 'latitude': '34.01453', 'longitude': '-118.263924'} ,
      {'name': 'Broadway 25 Cap', 'latitude': '33.913816', 'longitude': '-118.278702'} ,
      {'name': 'Broadway LA Pharmacy - 30 CAP', 'latitude': '33.951707999999996', 'longitude': '-118.277845'} ,
      {'name': 'Brown Dog Health and Wellness', 'latitude': '33.96135', 'longitude': '-116.499581'} ,
      {'name': 'Bud - Wax and Beyond', 'latitude': '33.935072', 'longitude': '-118.360827'} ,
      {'name': 'Bud and Bloom', 'latitude': '33.71985', 'longitude': '-117.852725'} ,
      {'name': 'BUD BAR Chula Vista', 'latitude': '32.613703', 'longitude': '-117.082327'} ,
      {'name': 'Bud King - PRE-ICO/Prop. D', 'latitude': '34.20824', 'longitude': '-118.535189'} ,
      {'name': 'Buddha Company - Arts District', 'latitude': '34.03042', 'longitude': '-118.230813'} ,
      {'name': 'Buddha Company - DTLA', 'latitude': '34.04136', 'longitude': '-118.25342'} ,
      {'name': 'Buds R Us 20 Cap', 'latitude': '33.955582', 'longitude': '-118.309197'} ,
      {'name': 'Burdank Blvd', 'latitude': '34.171899', 'longitude': '-118.362507'} ,
      {'name': 'CAC Venice', 'latitude': '34.002255', 'longitude': '-118.469196'} ,
      {'name': 'Cafe 710', 'latitude': '36.982654', 'longitude': '-119.716307'} ,
      {'name': 'Cali Connoisseur Club', 'latitude': '33.992841', 'longitude': '-117.89066'} ,
      {'name': 'Cali Emerald Care', 'latitude': '33.928895', 'longitude': '-116.938872'} ,
      {'name': 'CALI RELEAF CHURCH OF MIEN TAO', 'latitude': '34.109987', 'longitude': '-118.014637'} ,
      {'name': 'California Cannabis Crenshaw', 'latitude': '33.972214', 'longitude': '-118.331099'} ,
      {'name': 'California Cannabis Melrose', 'latitude': '34.0832', 'longitude': '-118.309961'} ,
      {'name': 'California Cannabis Soto', 'latitude': '34.020821', 'longitude': '-118.22059'} ,
      {'name': 'Caña Farms', 'latitude': '34.197807', 'longitude': '-118.597964'} ,
      {'name': 'Cana Harbor- Harbor City', 'latitude': '33.79501', 'longitude': '-118.298215'} ,
      {'name': 'Cana Sylmar', 'latitude': '34.30992', 'longitude': '-118.42784'} ,
      {'name': 'Canna Cloud', 'latitude': '33.858465', 'longitude': '-117.251465'} ,
      {'name': 'Cannabal City Collective - Los Angeles', 'latitude': '34.040085', 'longitude': '-118.237301'} ,
      {'name': 'Cannabis Local', 'latitude': '34.236191', 'longitude': '-118.411643'} ,
      {'name': 'Cannabliss', 'latitude': '34.078493', 'longitude': '-117.906844'} ,
      {'name': 'CannaBoss', 'latitude': '32.756089', 'longitude': '-117.092258'} ,
      {'name': 'CannaCopia Powered by MedMen', 'latitude': '36.073711', 'longitude': '-115.242095'} ,
      {'name': 'CannaCruz', 'latitude': '36.986428', 'longitude': '-122.032464'} ,
      {'name': 'Cannacruz Salinas Open 05/22!', 'latitude': '36.65465', 'longitude': '-121.630583'} ,
      {'name': 'Cannary West', 'latitude': '34.03732', 'longitude': '-118.432724'} ,
      {'name': 'Cannasseurs Club', 'latitude': '34.254479', 'longitude': '-118.467826'} ,
      {'name': 'Cannasseurs Club NOHO Grand Opening!!! 7/19/2019', 'latitude': '34.194376', 'longitude': '-118.377102'} ,
      {'name': 'CannaSutra Pre-ICO', 'latitude': '34.1413928', 'longitude': '-118.3719835'} ,
      {'name': 'Care Collective', 'latitude': '33.860195', 'longitude': '-118.15904'} ,
      {'name': 'Casa De Flor', 'latitude': '34.048468', 'longitude': '-118.097718'} ,
      {'name': 'Cathedral City Care Collective North', 'latitude': '33.843765', 'longitude': '-116.458597'} ,
      {'name': 'CBD A1 Snack Shop', 'latitude': '32.713425', 'longitude': '-117.160731'} ,
      {'name': 'CBD Choice Pomona', 'latitude': '34.062445', 'longitude': '-117.78723'} ,
      {'name': 'CBD City', 'latitude': '32.638242', 'longitude': '-117.077623'} ,
      {'name': 'CBD Greens', 'latitude': '34.062156', 'longitude': '-117.766564'} ,
      {'name': 'CBD Mall Kiosk - Mr. Tin Man&#x27;s', 'latitude': '33.178465', 'longitude': '-117.333717'} ,
      {'name': 'CBD OC Wellness Solutions (Hempstrax)', 'latitude': '33.631846', 'longitude': '-117.715899'} ,
      {'name': 'CBD OC Wellness Solutions - &quot;CBD Hemp Only &quot;', 'latitude': '33.80285', 'longitude': '-117.98897'} ,
      {'name': 'CBD Pluz', 'latitude': '34.093304', 'longitude': '-117.688354'} ,
      {'name': 'CBD Shop', 'latitude': '33.734454', 'longitude': '-117.812916'} ,
      {'name': 'CBD Shop', 'latitude': '33.494323', 'longitude': '-117.663589'} ,
      {'name': 'CBD Shop', 'latitude': '33.60344', 'longitude': '-117.693115'} ,
      {'name': 'CBD Shop', 'latitude': '33.658742', 'longitude': '-117.972564'} ,
      {'name': 'CBD Shop', 'latitude': '33.498588', 'longitude': '-117.660217'} ,
      {'name': 'CBD Superstore', 'latitude': '32.584328', 'longitude': '-117.100167'} ,
      {'name': 'CBD Wellness Studio - Mr. Tin Man&#x27;s', 'latitude': '32.595122', 'longitude': '-117.020458'} ,
      {'name': 'CBD Werx', 'latitude': '33.425885', 'longitude': '-117.610576'} ,
      {'name': 'CBD Werx', 'latitude': '33.714519', 'longitude': '-118.007046'} ,
      {'name': 'CBD Werx', 'latitude': '33.614176', 'longitude': '-117.704935'} ,
      {'name': 'CCA California Caregivers Alliance', 'latitude': '34.0822348', 'longitude': '-118.272079'} ,
      {'name': 'Central Hotbox', 'latitude': '33.970961', 'longitude': '-118.256164'} ,
      {'name': 'CHAI Cannabis Co', 'latitude': '36.989152', 'longitude': '-121.974352'} ,
      {'name': 'Chai Cannabis Co. - Castroville', 'latitude': '36.766469', 'longitude': '-121.758486'} ,
      {'name': 'CHC Canna Health Caregivers', 'latitude': '34.050333', 'longitude': '-118.362149'} ,
      {'name': 'Chronic Pain Releaf Center - Long Beach', 'latitude': '33.7856025', 'longitude': '-118.2156644'} ,
      {'name': 'Chula Vista Best Meds', 'latitude': '32.595227', 'longitude': '-117.061651'} ,
      {'name': 'Chula Vista Firehouse', 'latitude': '32.594999', 'longitude': '-117.059307'} ,
      {'name': 'Chula Vista Greens', 'latitude': '32.607712', 'longitude': '-117.067065'} ,
      {'name': 'Chula&#x27;s Fuego Meds', 'latitude': '32.627397', 'longitude': '-117.074155'} ,
      {'name': 'Church Of Holy Fire', 'latitude': '34.05', 'longitude': '-117.97'} ,
      {'name': 'Church of Natural Healers', 'latitude': '34.107324', 'longitude': '-117.296774'} ,
      {'name': 'Church of Newport Mesa', 'latitude': '33.639654', 'longitude': '-117.921176'} ,
      {'name': 'Church of Walnut', 'latitude': '34.149869', 'longitude': '-118.117903'} ,
      {'name': 'Circle Of Hope', 'latitude': '34.221652', 'longitude': '-118.501258'} ,
      {'name': 'Citadel Church of Crenshaw', 'latitude': '33.896259', 'longitude': '-118.327137'} ,
      {'name': 'City Compassionate Caregivers CCC', 'latitude': '34.034855', 'longitude': '-118.22343'} ,
      {'name': 'City of Dank', 'latitude': '33.785432', 'longitude': '-116.467744'} ,
      {'name': 'City Terrace Hotbox 20 Cap', 'latitude': '34.048946', 'longitude': '-118.189701'} ,
      {'name': 'Cloneville (CCSC)', 'latitude': '34.15008', 'longitude': '-118.379918'} ,
      {'name': 'CLOUD (20)', 'latitude': '34.016009', 'longitude': '-118.137363'} ,
      {'name': 'Cloud 8th DTLA', 'latitude': '34.017649', 'longitude': '-118.243825'} ,
      {'name': 'Cloud Nation', 'latitude': '33.99515', 'longitude': '-117.882129'} ,
      {'name': 'Club 101', 'latitude': '34.167019', 'longitude': '-118.59433'} ,
      {'name': 'CMB - California Medical Brokers', 'latitude': '36.757902', 'longitude': '-119.80292'} ,
      {'name': 'Coachella Lighthouse Dispensary', 'latitude': '33.700178', 'longitude': '-116.193968'} ,
      {'name': 'Coachella Valley Green Dragon', 'latitude': '33.818272', 'longitude': '-116.546842'} ,
      {'name': 'COAST TO COAST COLLECTIVE', 'latitude': '34.199973', 'longitude': '-118.5981'} ,
      {'name': 'Columbia Care California', 'latitude': '32.807987', 'longitude': '-117.217592'} ,
      {'name': 'Compassion Union', 'latitude': '34.193945', 'longitude': '-118.386923'} ,
      {'name': 'Compton 25 Cap', 'latitude': '33.896269', 'longitude': '-118.189163'} ,
      {'name': 'Compton Connect', 'latitude': '33.895964', 'longitude': '-118.191896'} ,
      {'name': 'Compton Cure', 'latitude': '33.895959', 'longitude': '-118.253701'} ,
      {'name': 'Connected Cannabis Co - Bellflower', 'latitude': '33.874399', 'longitude': '-118.141641'} ,
      {'name': 'Connected Cannabis Co - Belmont Shore', 'latitude': '33.760289', 'longitude': '-118.134235'} ,
      {'name': 'Connected Cannabis Co - Cherry', 'latitude': '33.814967', 'longitude': '-118.166711'} ,
      {'name': 'Connected Cannabis Co - Santa Ana', 'latitude': '33.714717', 'longitude': '-117.844943'} ,
      {'name': 'Cookies LA', 'latitude': '33.989661', 'longitude': '-118.203736'} ,
      {'name': 'Cookies Melrose', 'latitude': '34.083554', 'longitude': '-118.372365'} ,
      {'name': 'Cornerstone Research Collective', 'latitude': '34.142091', 'longitude': '-118.22198'} ,
      {'name': 'CPR', 'latitude': '34.228094', 'longitude': '-118.53711'} ,
      {'name': 'Creekside Wellness', 'latitude': '37.117168', 'longitude': '-122.12051'} ,
      {'name': 'Crenshaw Caregivers', 'latitude': '33.988792', 'longitude': '-118.333845'} ,
      {'name': 'Crenshaw Church of Herbs', 'latitude': '33.98737', 'longitude': '-118.331197'} ,
      {'name': 'Crenshaw Daily Green', 'latitude': '33.934703', 'longitude': '-118.32687'} ,
      {'name': 'Crestmore Smoke House', 'latitude': '34.07031', 'longitude': '-117.39341'} ,
      {'name': 'Crown Collective LA', 'latitude': '33.975134', 'longitude': '-118.251314'} ,
      {'name': 'Cultivate Las Vegas', 'latitude': '36.126126', 'longitude': '-115.188593'} ,
      {'name': 'Culver Healing Center - CHC', 'latitude': '34.015284', 'longitude': '-118.413603'} ,
      {'name': 'Curaleaf - 19th Ave', 'latitude': '33.64166', 'longitude': '-112.099421'} ,
      {'name': 'Curaleaf - Camelback', 'latitude': '33.50949', 'longitude': '-112.059057'} ,
      {'name': 'Curaleaf - Central', 'latitude': '33.416646', 'longitude': '-112.073105'} ,
      {'name': 'Curaleaf - Youngtown', 'latitude': '33.6044664', 'longitude': '-112.3006085'} ,
      {'name': 'Curaleaf Gilbert', 'latitude': '33.347707', 'longitude': '-111.831913'} ,
      {'name': 'Curaleaf Midtown', 'latitude': '33.481078', 'longitude': '-112.07412'} ,
      {'name': 'Curbstone Exchange', 'latitude': '37.056015', 'longitude': '-122.075328'} ,
      {'name': 'CWG 25 CAP', 'latitude': '33.89598', 'longitude': '-118.253047'} ,
      {'name': 'D2 Dispensary', 'latitude': '32.20687', 'longitude': '-110.84014'} ,
      {'name': 'Daddy&#x27;s Dispensary', 'latitude': '34.198594', 'longitude': '-118.491917'} ,
      {'name': 'Daily Releaf', 'latitude': '33.634136', 'longitude': '-117.716771'} ,
      {'name': 'DANK BANK 20 CAP COLLECTIVE', 'latitude': '33.962244', 'longitude': '-118.070355'} ,
      {'name': 'Dank Depot', 'latitude': '33.88639', 'longitude': '-118.26778'} ,
      {'name': 'Dank Depot', 'latitude': '33.815501', 'longitude': '-116.474942'} ,
      {'name': 'Dank House Collective', 'latitude': '34.042214', 'longitude': '-118.18446'} ,
      {'name': 'Dank Stop', 'latitude': '34.006027', 'longitude': '-117.425605'} ,
      {'name': 'Dank Trees Fullerton', 'latitude': '33.869185', 'longitude': '-117.914151'} ,
      {'name': 'Dankilicious 15 Cap', 'latitude': '34.050911', 'longitude': '-118.345418'} ,
      {'name': 'DC Collective', 'latitude': '34.216779', 'longitude': '-118.595255'} ,
      {'name': 'Debbie&#x27;s Dispensary', 'latitude': '33.668575', 'longitude': '-112.280833'} ,
      {'name': 'Debbie&#x27;s Dispensary', 'latitude': '35.052646', 'longitude': '-114.586936'} ,
      {'name': 'Debbie&#x27;s Dispensary', 'latitude': '33.670953', 'longitude': '-112.117898'} ,
      {'name': 'Deep Roots Harvest', 'latitude': '36.805409', 'longitude': '-114.120735'} ,
      {'name': 'Delta 9 THC 21+', 'latitude': '33.780288', 'longitude': '-118.252246'} ,
      {'name': 'Desert Bloom Re-Leaf Center', 'latitude': '32.205911', 'longitude': '-110.820542'} ,
      {'name': 'DESERT ORGANIC SOLUTIONS - Palm Springs 92258', 'latitude': '33.9067099', 'longitude': '-116.549333'} ,
      {'name': 'Desert Rose Phoenix', 'latitude': '33.712184', 'longitude': '-112.082482'} ,
      {'name': 'Deserts Finest', 'latitude': '33.960645', 'longitude': '-116.501145'} ,
      {'name': 'Diamond Collective 20 Cap', 'latitude': '34.117251', 'longitude': '-117.291664'} ,
      {'name': 'Divine Wellness Center', 'latitude': '34.202437', 'longitude': '-118.595155'} ,
      {'name': 'DLC', 'latitude': '34.10702', 'longitude': '-117.399517'} ,
      {'name': 'dosist', 'latitude': '33.99059', 'longitude': '-118.46514'} ,
      {'name': 'dosist', 'latitude': '34.071746', 'longitude': '-118.364441'} ,
      {'name': 'Douglas Greens', 'latitude': '32.794042', 'longitude': '-116.964987'} ,
      {'name': 'Downtown Fantastic 20 Cap', 'latitude': '34.042105', 'longitude': '-118.280001'} ,
      {'name': 'Dr Doolittle&#x27;s', 'latitude': '34.071101', 'longitude': '-117.394506'} ,
      {'name': 'Dr. Greenthumb&#x27;s - Sylmar', 'latitude': '34.291486', 'longitude': '-118.412557'} ,
      {'name': 'Dr. GT LA', 'latitude': '33.974853', 'longitude': '-118.288895'} ,
      {'name': 'DTPG', 'latitude': '34.026479', 'longitude': '-118.238727'} ,
      {'name': 'DWG 25 CAP', 'latitude': '34.024332', 'longitude': '-118.1928'} ,
      {'name': 'Dynamic Meds', 'latitude': '33.855741', 'longitude': '-117.255457'} ,
      {'name': 'Earth&#x27;s Healing', 'latitude': '32.167339', 'longitude': '-110.94078'} ,
      {'name': 'Earth&#x27;s Healing North', 'latitude': '32.297284', 'longitude': '-110.972795'} ,
      {'name': 'East LA Collective 25 CAP', 'latitude': '34.086089', 'longitude': '-118.17746'} ,
      {'name': 'East of Eden Cannabis Co.', 'latitude': '36.670252', 'longitude': '-121.64256'} ,
      {'name': 'Eastlake Greens Collective', 'latitude': '32.654844', 'longitude': '-116.95741'} ,
      {'name': 'eClouds CBD', 'latitude': '34.007836', 'longitude': '-117.964472'} ,
      {'name': 'El Cajon Meds', 'latitude': '32.792726', 'longitude': '-116.969674'} ,
      {'name': 'ELAC / EAST LA CANNABIS', 'latitude': '34.013599', 'longitude': '-118.143684'} ,
      {'name': 'Elevate Lompoc', 'latitude': '34.638098', 'longitude': '-120.457491'} ,
      {'name': 'Elevate Open Now!', 'latitude': '34.171523', 'longitude': '-118.568732'} ,
      {'name': 'Emerald Perspective', 'latitude': '34.15472', 'longitude': '-119.200272'} ,
      {'name': 'Emerald Phoenix', 'latitude': '33.567767', 'longitude': '-112.150391'} ,
      {'name': 'Emerald Skyway', 'latitude': '36.659469', 'longitude': '-121.609394'} ,
      {'name': 'Empire Gardens - Perris', 'latitude': '33.847341', 'longitude': '-117.226781'} ,
      {'name': 'Encanto Dispensary', 'latitude': '33.473496', 'longitude': '-112.116006'} ,
      {'name': 'ERBA Markets', 'latitude': '34.028134', 'longitude': '-118.452265'} ,
      {'name': 'Essence Dispensary | Henderson', 'latitude': '36.07233', 'longitude': '-115.080533'} ,
      {'name': 'Essence Dispensary | Las Vegas Strip', 'latitude': '36.145164', 'longitude': '-115.156191'} ,
      {'name': 'Essence Dispensary | West', 'latitude': '36.099943', 'longitude': '-115.220083'} ,
      {'name': 'Euclid Medical Center', 'latitude': '33.864968', 'longitude': '-117.942542'} ,
      {'name': 'Euflora Long Beach', 'latitude': '33.860372', 'longitude': '-118.178019'} ,
      {'name': 'Euphoria Wellness', 'latitude': '36.0478877', 'longitude': '-115.2244706'} ,
      {'name': 'Euphoric', 'latitude': '34.0427874', 'longitude': '-118.4237806'} ,
      {'name': 'Evergreen - Santa Ana 92705', 'latitude': '33.726387', 'longitude': '-117.85266'} ,
      {'name': 'EVOLV Cannabis', 'latitude': '33.787102', 'longitude': '-118.308087'} ,
      {'name': 'EXCLUSIVE 25 CAP PRE-ICO', 'latitude': '33.983001', 'longitude': '-118.314571'} ,
      {'name': 'Exhale', 'latitude': '36.115682', 'longitude': '-115.199298'} ,
      {'name': 'EXHALE MED CENTER', 'latitude': '34.088813', 'longitude': '-118.376318'} ,
      {'name': 'Exhalence', 'latitude': '34.221922', 'longitude': '-118.359563'} ,
      {'name': 'Exotics', 'latitude': '33.747897', 'longitude': '-116.947915'} ,
      {'name': 'Exotics - Culver City *Coming Soon*', 'latitude': '34.024482', 'longitude': '-118.410919'} ,
      {'name': 'Fair Oaks Collective', 'latitude': '34.182279', 'longitude': '-118.150534'} ,
      {'name': 'FAM', 'latitude': '33.975932', 'longitude': '-117.909688'} ,
      {'name': 'Farm Fresh Medical Marijuana Dispensary', 'latitude': '34.498545', 'longitude': '-114.347285'} ,
      {'name': 'Field Of Dreams', 'latitude': '34.050216', 'longitude': '-118.437645'} ,
      {'name': 'Fire Factory 15 Cap', 'latitude': '34.014402', 'longitude': '-118.202084'} ,
      {'name': 'FireHaus', 'latitude': '34.0421', 'longitude': '-118.44158'} ,
      {'name': 'Firehouse 365', 'latitude': '33.982924', 'longitude': '-118.186014'} ,
      {'name': 'FireHouse 64', 'latitude': '33.853905', 'longitude': '-117.244563'} ,
      {'name': 'Firestone 20 CAP', 'latitude': '33.957762', 'longitude': '-118.231368'} ,
      {'name': 'Five Star', 'latitude': '32.595132', 'longitude': '-117.063087'} ,
      {'name': 'FLIGHT CLUB 30 CAP COLLECTIVE', 'latitude': '34.099493', 'longitude': '-117.40121'} ,
      {'name': 'Flower Shop', 'latitude': '33.918132', 'longitude': '-117.242535'} ,
      {'name': 'Fly High 20 Collective', 'latitude': '33.903174', 'longitude': '-118.223228'} ,
      {'name': 'FOOTHILL WELLNESS CENTER - Pre ICO', 'latitude': '34.247969', 'longitude': '-118.287349'} ,
      {'name': 'Fountain of Wellbeing - FWB', 'latitude': '34.202133', 'longitude': '-118.39138'} ,
      {'name': 'From The Earth', 'latitude': '33.706456', 'longitude': '-117.864691'} ,
      {'name': 'From The Earth', 'latitude': '33.706456', 'longitude': '-117.864691'} ,
      {'name': 'From the Earth - Port Hueneme, Ventura County', 'latitude': '34.17832', 'longitude': '-119.196375'} ,
      {'name': 'Fullerton Church of Healing', 'latitude': '33.864777', 'longitude': '-117.90959'} ,
      {'name': 'G-Spot 25 Cap Collective', 'latitude': '33.896334', 'longitude': '-118.195132'} ,
      {'name': 'Gardena Gardens', 'latitude': '33.883652', 'longitude': '-118.290989'} ,
      {'name': 'GEC', 'latitude': '33.896258', 'longitude': '-118.327136'} ,
      {'name': 'Genesis Marketplace', 'latitude': '36.886217', 'longitude': '-121.394348'} ,
      {'name': 'Giving Tree Dispensary', 'latitude': '33.6823831', 'longitude': '-112.0844063'} ,
      {'name': 'Glendale Greenhouse', 'latitude': '33.65344', 'longitude': '-112.234873'} ,
      {'name': 'Globe Farmacy', 'latitude': '33.396819', 'longitude': '-110.787622'} ,
      {'name': 'GOLD 20 Cap Collective', 'latitude': '34.007076', 'longitude': '-118.278372'} ,
      {'name': 'Golden Lotus', 'latitude': '33.98974', 'longitude': '-117.890443'} ,
      {'name': 'Golden State Greens Point Loma', 'latitude': '32.756379', 'longitude': '-117.208176'} ,
      {'name': 'Goldn Bloom', 'latitude': '32.714018', 'longitude': '-117.121234'} ,
      {'name': 'Goldn Bloom', 'latitude': '32.714018', 'longitude': '-117.121234'} ,
      {'name': 'Grace Medical', 'latitude': '34.028556', 'longitude': '-118.453677'} ,
      {'name': 'Granada Gardens 20 Cap', 'latitude': '34.265109', 'longitude': '-118.522024'} ,
      {'name': 'Grande Vista 15 CAP', 'latitude': '34.01828', 'longitude': '-118.20998'} ,
      {'name': 'Gravity Greens', 'latitude': '32.785262', 'longitude': '-116.977917'} ,
      {'name': 'Green America', 'latitude': '33.858757', 'longitude': '-117.251924'} ,
      {'name': 'Green Angel (Pre-ICO)', 'latitude': '34.148845', 'longitude': '-118.437371'} ,
      {'name': 'Green Angel Pre-Ico', 'latitude': '34.166073', 'longitude': '-118.621991'} ,
      {'name': 'Green Awning Collective - GAC', 'latitude': '34.166493', 'longitude': '-118.620961'} ,
      {'name': 'Green City Collective', 'latitude': '33.957087', 'longitude': '-118.443269'} ,
      {'name': 'Green Cross of Torrance', 'latitude': '33.831098', 'longitude': '-118.3086093'} ,
      {'name': 'Green Cross Pharma - Rancho Mirage, Palm Springs, Palm Desert', 'latitude': '33.785472', 'longitude': '-116.462573'} ,
      {'name': 'GREEN DRAGON', 'latitude': '34.20222', 'longitude': '-118.42461'} ,
      {'name': 'Green Dream Caregivers 24 Hours', 'latitude': '33.978935', 'longitude': '-118.300026'} ,
      {'name': 'Green Earth Collective', 'latitude': '34.122485', 'longitude': '-118.210695'} ,
      {'name': 'Green Farmacy', 'latitude': '31.382484', 'longitude': '-109.923182'} ,
      {'name': 'Green Garden Collective', 'latitude': '33.930478', 'longitude': '-118.291258'} ,
      {'name': 'Green Gears 20 Cap', 'latitude': '33.937498', 'longitude': '-118.292335'} ,
      {'name': 'Green Ghost', 'latitude': '34.014496', 'longitude': '-118.26346'} ,
      {'name': 'Green Goddess Collective', 'latitude': '33.987753', 'longitude': '-118.47047'} ,
      {'name': 'GREEN GORILLAZ Express', 'latitude': '34.015341', 'longitude': '-118.132037'} ,
      {'name': 'Green Highway', 'latitude': '34.041383', 'longitude': '-118.169009'} ,
      {'name': 'Green Lady 24 Hours', 'latitude': '32.608901', 'longitude': '-117.066901'} ,
      {'name': 'Green Leaf Wellness', 'latitude': '33.9592996', 'longitude': '-116.5039313'} ,
      {'name': 'Green Lit Pharms', 'latitude': '34.049394', 'longitude': '-117.996341'} ,
      {'name': 'Green Lotus LA', 'latitude': '33.972579', 'longitude': '-118.308973'} ,
      {'name': 'Green Med Wellness Center', 'latitude': '32.244667', 'longitude': '-110.85445'} ,
      {'name': 'Green Pearl Organics', 'latitude': '33.9752714', 'longitude': '-116.5309007'} ,
      {'name': 'Green State University 20 Cap', 'latitude': '34.025523', 'longitude': '-118.270856'} ,
      {'name': 'Greenbear 20 Cap Collective', 'latitude': '34.025136', 'longitude': '-118.23904'} ,
      {'name': 'GreenBuds Collective', 'latitude': '33.895904', 'longitude': '-118.203337'} ,
      {'name': 'Greenhouse Herbal Center, LLC', 'latitude': '34.1016334', 'longitude': '-118.3041071'} ,
      {'name': 'Greenhouse of Flagstaff', 'latitude': '35.197729', 'longitude': '-111.634979'} ,
      {'name': 'Greenlight Discount Pharmacy Pre-ICO', 'latitude': '34.307005', 'longitude': '-118.470101'} ,
      {'name': 'GREENPHARMS Dispensary Mesa', 'latitude': '33.388012', 'longitude': '-111.676688'} ,
      {'name': 'GREENWOLF LA Pre ICO', 'latitude': '34.125669', 'longitude': '-118.263905'} ,
      {'name': 'Greenwoods 20 CAP Collective', 'latitude': '34.061055', 'longitude': '-117.754826'} ,
      {'name': 'GTR NOHO', 'latitude': '34.161932', 'longitude': '-118.370005'} ,
      {'name': 'GTR- Green Tree Remedy ONAC -Fullerton', 'latitude': '33.864659', 'longitude': '-117.924694'} ,
      {'name': 'GW Inc', 'latitude': '34.208088', 'longitude': '-118.388284'} ,
      {'name': 'H Street Patient Care', 'latitude': '34.839474', 'longitude': '-114.608139'} ,
      {'name': 'H.A.I Holistic Alternative Inc.', 'latitude': '34.227216', 'longitude': '-118.531973'} ,
      {'name': 'H3 Holistic Heart Healing', 'latitude': '33.657476', 'longitude': '-117.901704'} ,
      {'name': 'HACC - Torrance', 'latitude': '33.831736', 'longitude': '-118.306984'} ,
      {'name': 'Hacienda Med Center', 'latitude': '34.008373', 'longitude': '-117.95668'} ,
      {'name': 'Hall of Flame', 'latitude': '33.973614', 'longitude': '-118.225048'} ,
      {'name': 'Hana Green Valley', 'latitude': '31.896652', 'longitude': '-111.003039'} ,
      {'name': 'Hana Kingman', 'latitude': '35.2539174', 'longitude': '-114.0277419'} ,
      {'name': 'Happy Leaf Collective', 'latitude': '34.044288', 'longitude': '-118.432401'} ,
      {'name': 'Harbor Collective', 'latitude': '32.692315', 'longitude': '-117.139149'} ,
      {'name': 'Harbor Holy Fire', 'latitude': '33.649926', 'longitude': '-117.919533'} ,
      {'name': 'Harbor&#x27;s Finest', 'latitude': '33.78779', 'longitude': '-118.30293'} ,
      {'name': 'Harvest of Baseline', 'latitude': '33.377682', 'longitude': '-111.966797'} ,
      {'name': 'Harvest of Cottonwood', 'latitude': '34.721644', 'longitude': '-112.000496'} ,
      {'name': 'Harvest of Glendale (Newly Opened)', 'latitude': '33.609368', 'longitude': '-112.185397'} ,
      {'name': 'Harvest of Havasu (Formerly High Desert Healing)', 'latitude': '34.493992', 'longitude': '-114.350737'} ,
      {'name': 'Harvest of Scottsdale', 'latitude': '33.624038', 'longitude': '-111.903707'} ,
      {'name': 'Harvest of Tempe', 'latitude': '33.349496', 'longitude': '-111.9485747'} ,
      {'name': 'Harvest of Tucson', 'latitude': '32.250169', 'longitude': '-110.931273'} ,
      {'name': 'Have a Heart - Coalinga', 'latitude': '36.140457', 'longitude': '-120.36084'} ,
      {'name': 'HAVEN (Formerly ShowGrow)', 'latitude': '33.720098', 'longitude': '-117.846763'} ,
      {'name': 'Hawthorne SuperBudz', 'latitude': '33.911949', 'longitude': '-118.361509'} ,
      {'name': 'HCMA - NC Co-Op, Inc.', 'latitude': '34.241126', 'longitude': '-118.390294'} ,
      {'name': 'Health for Life AZ East Mesa', 'latitude': '33.284476', 'longitude': '-111.640001'} ,
      {'name': 'Health for Life AZ North Mesa', 'latitude': '33.466645', 'longitude': '-111.710373'} ,
      {'name': 'Health for Life Crimson', 'latitude': '33.414775', 'longitude': '-111.615702'} ,
      {'name': 'Heaven - Fresno, Clovis', 'latitude': '36.824972', 'longitude': '-119.790711'} ,
      {'name': 'Herbal Pain Relief Center', 'latitude': '34.2652813', 'longitude': '-118.4670804'} ,
      {'name': 'Herbal Remedies Caregivers', 'latitude': '34.295557', 'longitude': '-118.412265'} ,
      {'name': 'Herbal Wellness Center - Phoenix', 'latitude': '33.495329', 'longitude': '-112.147216'} ,
      {'name': 'HHC', 'latitude': '34.023771', 'longitude': '-117.917516'} ,
      {'name': 'HHC - Healthy Herbal Care', 'latitude': '34.200152', 'longitude': '-118.466586'} ,
      {'name': 'HHC Plus', 'latitude': '34.020211', 'longitude': '-118.239628'} ,
      {'name': 'Hidden Leaf', 'latitude': '34.027655', 'longitude': '-117.965359'} ,
      {'name': 'HIGH FIVE *Grand Opening*', 'latitude': '33.94008', 'longitude': '-117.972732'} ,
      {'name': 'HIGH LIFE COLLECTIVE', 'latitude': '34.02205', 'longitude': '-118.166173'} ,
      {'name': 'High Mountain Health', 'latitude': '35.188102', 'longitude': '-111.662584'} ,
      {'name': 'High Season - Adelanto', 'latitude': '34.571637', 'longitude': '-117.402659'} ,
      {'name': 'High Season - DTLA', 'latitude': '34.02589', 'longitude': '-118.23261'} ,
      {'name': 'High Season - Perris', 'latitude': '33.858554', 'longitude': '-117.251038'} ,
      {'name': 'High Society Wellness Center', 'latitude': '34.056549', 'longitude': '-118.208297'} ,
      {'name': 'High Standard', 'latitude': '33.945194', 'longitude': '-118.354539'} ,
      {'name': 'Higher Level - Seaside', 'latitude': '36.607014', 'longitude': '-121.855424'} ,
      {'name': 'Holistic Patient Wellness Group', 'latitude': '33.056627', 'longitude': '-109.301664'} ,
      {'name': 'HOLLYWEED DISPENSARY', 'latitude': '34.044844', 'longitude': '-118.352704'} ,
      {'name': 'Hollywood High Grade', 'latitude': '34.090861', 'longitude': '-118.343381'} ,
      {'name': 'Hollywood Holistic', 'latitude': '34.09857', 'longitude': '-118.307933'} ,
      {'name': 'Hollywood House of Herbs 20 Cap', 'latitude': '34.161159', 'longitude': '-118.372832'} ,
      {'name': 'Homeland&#x27;s Greenhouse', 'latitude': '33.743051', 'longitude': '-117.109617'} ,
      {'name': 'HOOVER GREENS', 'latitude': '33.98391', 'longitude': '-118.2872'} ,
      {'name': 'Hot Box 20 Cap Collective', 'latitude': '33.979507', 'longitude': '-118.240861'} ,
      {'name': 'HOTN Cultivation Co.', 'latitude': '33.844691', 'longitude': '-116.459588'} ,
      {'name': 'House of Buddha', 'latitude': '33.79823', 'longitude': '-118.309365'} ,
      {'name': 'HOUSE OF FIRE Express', 'latitude': '34.147315', 'longitude': '-118.068365'} ,
      {'name': 'House of Herbal Healing Church', 'latitude': '34.047565', 'longitude': '-118.271094'} ,
      {'name': 'House of Kush', 'latitude': '33.974834', 'longitude': '-118.28527'} ,
      {'name': 'House of Lucidity', 'latitude': '33.783936', 'longitude': '-116.467193'} ,
      {'name': 'HP Greens', 'latitude': '33.973071', 'longitude': '-118.230642'} ,
      {'name': 'HP Pharmacy', 'latitude': '33.973667', 'longitude': '-118.221412'} ,
      {'name': 'HPC - Hueneme Patient Collective', 'latitude': '34.176538', 'longitude': '-119.211813'} ,
      {'name': 'Huntington Park Collective', 'latitude': '33.962649', 'longitude': '-118.233507'} ,
      {'name': 'Iguana Collective', 'latitude': '33.784458', 'longitude': '-116.467932'} ,
      {'name': 'Infinite Wellness 20 Cap', 'latitude': '33.996866', 'longitude': '-118.278516'} ,
      {'name': 'INFINITY MEDICAL ALLIANCE - Torrance', 'latitude': '33.818082', 'longitude': '-118.305964'} ,
      {'name': 'Inyo Fine Cannabis Dispensary | Las Vegas', 'latitude': '36.143907', 'longitude': '-115.135687'} ,
      {'name': 'ISMOKE420', 'latitude': '33.431897', 'longitude': '-117.139595'} ,
      {'name': 'IVTHC', 'latitude': '33.964774', 'longitude': '-116.501739'} ,
      {'name': 'Jah Healing Kemetic Temple of The Divine Church', 'latitude': '34.260944', 'longitude': '-116.843374'} ,
      {'name': 'Jah Healing Kemetic Temple of the Divine Church South Gate', 'latitude': '33.944539', 'longitude': '-118.208356'} ,
      {'name': 'Jamestown Yuma', 'latitude': '32.67024', 'longitude': '-114.56004'} ,
      {'name': 'Jardin Premium Cannabis Dispensary', 'latitude': '36.130364', 'longitude': '-115.110898'} ,
      {'name': 'Jenny&#x27;s Dispensary - Henderson', 'latitude': '36.002027', 'longitude': '-115.106387'} ,
      {'name': 'Jenny&#x27;s Dispensary - North Las Vegas', 'latitude': '36.260369', 'longitude': '-115.206797'} ,
      {'name': 'Jet Room', 'latitude': '34.568047', 'longitude': '-117.400137'} ,
      {'name': 'Joy of Life Wellness Center', 'latitude': '33.861575', 'longitude': '-116.546414'} ,
      {'name': 'Kaneh Bosm Healing Ministry', 'latitude': '33.667056', 'longitude': '-117.672677'} ,
      {'name': 'Kannabis Works', 'latitude': '33.717998', 'longitude': '-117.913497'} ,
      {'name': 'Killer Meds 30CAP', 'latitude': '33.789778', 'longitude': '-118.306936'} ,
      {'name': 'Kind Brothers', 'latitude': '35.379808', 'longitude': '-118.995627'} ,
      {'name': 'Kind Meds Inc', 'latitude': '33.376143', 'longitude': '-111.844449'} ,
      {'name': 'KindPeoples - Ocean Street', 'latitude': '36.975821', 'longitude': '-122.019767'} ,
      {'name': 'KindPeoples - Soquel Avenue', 'latitude': '36.985755', 'longitude': '-121.982663'} ,
      {'name': 'King Kush Collective of LOS ANGELES', 'latitude': '33.954821', 'longitude': '-118.30879'} ,
      {'name': 'King&#x27;s Crew', 'latitude': '33.77477', 'longitude': '-118.122395'} ,
      {'name': 'KoreaTown Collective / Ktown Collective on Melrose', 'latitude': '34.083421', 'longitude': '-118.350991'} ,
      {'name': 'Kush Klub Collective', 'latitude': '34.021688', 'longitude': '-118.161685'} ,
      {'name': 'Kush Mart Spring Valley', 'latitude': '32.710489', 'longitude': '-116.99744'} ,
      {'name': 'Kush Pharm', 'latitude': '34.21319', 'longitude': '-118.499497'} ,
      {'name': 'Kush Temple', 'latitude': '34.009341', 'longitude': '-117.925953'} ,
      {'name': 'Kush Town', 'latitude': '34.057892', 'longitude': '-118.300164'} ,
      {'name': 'Kushism Van Nuys', 'latitude': '34.208235', 'longitude': '-118.483965'} ,
      {'name': 'KUSHLAND', 'latitude': '33.961571', 'longitude': '-118.27863'} ,
      {'name': 'Kushology 5 Grams', 'latitude': '33.999555', 'longitude': '-118.300433'} ,
      {'name': 'La Brea Collective - LBC', 'latitude': '34.048176', 'longitude': '-118.347761'} ,
      {'name': 'La Brea Gardens 25 Cap', 'latitude': '34.050989', 'longitude': '-118.34468'} ,
      {'name': 'LA Cannabis Co. - Inglewood', 'latitude': '33.985454', 'longitude': '-118.311999'} ,
      {'name': 'LA Cannabis Co. - La Brea', 'latitude': '34.045424', 'longitude': '-118.345797'} ,
      {'name': 'LA Cannabis Co. - Los Angeles', 'latitude': '34.018748', 'longitude': '-118.319333'} ,
      {'name': 'LA CBD', 'latitude': '34.08542', 'longitude': '-118.28668'} ,
      {'name': 'LA GAS HOUSE', 'latitude': '34.049193', 'longitude': '-118.195751'} ,
      {'name': 'LA Hotbox Collective', 'latitude': '34.04511', 'longitude': '-118.204094'} ,
      {'name': 'LA Kush - Los Angeles Kush - Echo Park', 'latitude': '34.064294', 'longitude': '-118.271457'} ,
      {'name': 'LA Meds 4 less', 'latitude': '34.0255439', 'longitude': '-118.240412'} ,
      {'name': 'LA Treehouse', 'latitude': '33.974322', 'longitude': '-118.29693'} ,
      {'name': 'LA Wonderland Marijuana Dispensary Recreational', 'latitude': '34.0344313', 'longitude': '-118.2640434'} ,
      {'name': 'Lake Forest Healing Center', 'latitude': '33.643557', 'longitude': '-117.667692'} ,
      {'name': 'Lakeside Meds', 'latitude': '32.856802', 'longitude': '-116.929217'} ,
      {'name': 'Las Vegas ReLeaf | Las Vegas Strip', 'latitude': '36.143777', 'longitude': '-115.154875'} ,
      {'name': 'LAVC', 'latitude': '34.063139', 'longitude': '-118.363085'} ,
      {'name': 'LAXCC 21+', 'latitude': '33.961924', 'longitude': '-118.420744'} ,
      {'name': 'LB Collective', 'latitude': '33.874799', 'longitude': '-118.171179'} ,
      {'name': 'LEAF', 'latitude': '34.63926', 'longitude': '-120.462713'} ,
      {'name': 'Leaf and Lion', 'latitude': '33.881549', 'longitude': '-118.162546'} ,
      {'name': 'Leaf Life', 'latitude': '32.881056', 'longitude': '-111.707173'} ,
      {'name': 'Leef Industries', 'latitude': '33.845449', 'longitude': '-116.505186'} ,
      {'name': 'Level Up', 'latitude': '33.621128', 'longitude': '-111.909161'} ,
      {'name': 'Level Up Tempe', 'latitude': '33.42273', 'longitude': '-111.974875'} ,
      {'name': 'Libra', 'latitude': '33.758727', 'longitude': '-116.303506'} ,
      {'name': 'Lights Out 20 Cap', 'latitude': '33.881469', 'longitude': '-118.290633'} ,
      {'name': 'Lights Out Wellness', 'latitude': '34.861377', 'longitude': '-118.16408'} ,
      {'name': 'Lit Co', 'latitude': '34.039396', 'longitude': '-118.267763'} ,
      {'name': 'Local Joint Phoenix', 'latitude': '33.421584', 'longitude': '-111.991053'} ,
      {'name': 'Long Beach Green Room', 'latitude': '33.775542', 'longitude': '-118.170147'} ,
      {'name': 'Long Beach Wellness Center', 'latitude': '33.810206', 'longitude': '-118.116795'} ,
      {'name': 'Long Lodge Tribal Enterprises', 'latitude': '33.647327', 'longitude': '-117.918958'} ,
      {'name': 'Long Lodge Tribal Enterprises', 'latitude': '33.647327', 'longitude': '-117.918958'} ,
      {'name': 'Los Angeles Farmers - (AHPS)', 'latitude': '34.02736', 'longitude': '-118.25302'} ,
      {'name': 'Los Angeles Kush - LA Kush', 'latitude': '34.073396', 'longitude': '-118.163739'} ,
      {'name': 'Los Angeles Patients &amp; Caregivers Group LAPCG', 'latitude': '34.0908316', 'longitude': '-118.3467033'} ,
      {'name': 'Loud 20 Cap', 'latitude': '33.974956', 'longitude': '-118.272003'} ,
      {'name': 'Love Faith and Unity', 'latitude': '34.037823', 'longitude': '-117.395833'} ,
      {'name': 'Main Spot', 'latitude': '34.194437', 'longitude': '-118.43162'} ,
      {'name': 'Malibu Community Collective', 'latitude': '34.039928', 'longitude': '-118.665605'} ,
      {'name': 'Manchester Remedy', 'latitude': '33.960202', 'longitude': '-118.316815'} ,
      {'name': 'Mankind Dispensary', 'latitude': '32.88051', 'longitude': '-117.160564'} ,
      {'name': 'March and Ash', 'latitude': '32.769686', 'longitude': '-117.132793'} ,
      {'name': 'Marina Caregivers, Inc.', 'latitude': '33.990801', 'longitude': '-118.445791'} ,
      {'name': 'Mars Collective', 'latitude': '35.313466', 'longitude': '-119.003276'} ,
      {'name': 'Mary J&#x27;s Cafe', 'latitude': '34.200729', 'longitude': '-118.600547'} ,
      {'name': 'Mary Janes - Hollywood', 'latitude': '34.083579', 'longitude': '-118.30708'} ,
      {'name': 'MECA', 'latitude': '34.034109', 'longitude': '-117.949968'} ,
      {'name': 'Med 25', 'latitude': '33.77844', 'longitude': '-118.26081'} ,
      {'name': 'MEDFORD MEDS - OPEN 24 HOURS!', 'latitude': '34.05933', 'longitude': '-118.184795'} ,
      {'name': 'Medical Caregivers Company', 'latitude': '34.0595878', 'longitude': '-118.2157238'} ,
      {'name': 'MedMart 30 CAP', 'latitude': '33.98662', 'longitude': '-118.27809'} ,
      {'name': 'MedMen Abbot Kinney', 'latitude': '33.990829', 'longitude': '-118.467878'} ,
      {'name': 'MedMen Beverly Hills', 'latitude': '34.074921', 'longitude': '-118.38352'} ,
      {'name': 'MedMen Downtown (Arts District)', 'latitude': '36.161071', 'longitude': '-115.148589'} ,
      {'name': 'MedMen Downtown (DTLA)', 'latitude': '34.044597', 'longitude': '-118.254446'} ,
      {'name': 'MedMen Kearny Mesa', 'latitude': '32.834977', 'longitude': '-117.152042'} ,
      {'name': 'MedMen LAX Airport', 'latitude': '33.957636', 'longitude': '-118.396137'} ,
      {'name': 'MedMen Lincoln Blvd.', 'latitude': '34.00079', 'longitude': '-118.466121'} ,
      {'name': 'MedMen Santa Ana', 'latitude': '33.717456', 'longitude': '-117.846601'} ,
      {'name': 'Medmen Sorrento Valley', 'latitude': '32.898849', 'longitude': '-117.22042'} ,
      {'name': 'MedMen The Strip (Paradise &amp; Harmon)', 'latitude': '36.107073', 'longitude': '-115.152561'} ,
      {'name': 'MedMen West Hollywood', 'latitude': '34.0906176', 'longitude': '-118.3673044'} ,
      {'name': 'Melrose Place 25 Cap', 'latitude': '34.083805', 'longitude': '-118.32348'} ,
      {'name': 'Members Only Collective', 'latitude': '33.917275', 'longitude': '-118.047355'} ,
      {'name': 'Merica Cannabis Company', 'latitude': '34.206955', 'longitude': '-118.396719'} ,
      {'name': 'Metro Meds AZ', 'latitude': '33.5651587', 'longitude': '-112.1370601'} ,
      {'name': 'Metro Meds Dispensary', 'latitude': '33.578082', 'longitude': '-112.124987'} ,
      {'name': 'Mias Exotics', 'latitude': '32.612278', 'longitude': '-117.068238'} ,
      {'name': 'Mile High Dispensary, LLC', 'latitude': '36.728739', 'longitude': '-119.780377'} ,
      {'name': 'Mint Dispensary', 'latitude': '33.39335', 'longitude': '-111.82451'} ,
      {'name': 'Mission Hills Patients Collective - Northridge / Reseda / Chatsworth', 'latitude': '34.1972522', 'longitude': '-118.5348403'} ,
      {'name': 'Mission Meds', 'latitude': '34.011376', 'longitude': '-117.494934'} ,
      {'name': 'Mission North Mountain', 'latitude': '33.566847', 'longitude': '-112.115598'} ,
      {'name': 'Mission PR', 'latitude': '33.99595', 'longitude': '-117.405247'} ,
      {'name': 'MJ 13 Elevated', 'latitude': '36.815665', 'longitude': '-119.78232'} ,
      {'name': 'MMD Hollywood', 'latitude': '34.098493', 'longitude': '-118.329756'} ,
      {'name': 'MMD Long Beach', 'latitude': '33.791795', 'longitude': '-118.185235'} ,
      {'name': 'MMD Marina Del Rey', 'latitude': '33.992126', 'longitude': '-118.443679'} ,
      {'name': 'MMD North Hollywood', 'latitude': '34.1561445', 'longitude': '-118.3702321'} ,
      {'name': 'MMJ America - Las Vegas', 'latitude': '36.105288', 'longitude': '-115.207277'} ,
      {'name': 'MMJ APOTHECARY', 'latitude': '33.964173', 'longitude': '-112.753281'} ,
      {'name': 'Modern Buds', 'latitude': '33.76429', 'longitude': '-118.148223'} ,
      {'name': 'Monarch Powered by MedMen', 'latitude': '33.554083', 'longitude': '-111.892685'} ,
      {'name': 'Monterey Bay Alternative Medicine', 'latitude': '36.5989096', 'longitude': '-121.8502734'} ,
      {'name': 'Monterey Holistic', 'latitude': '36.613819', 'longitude': '-121.902259'} ,
      {'name': 'MOTA (Medicine Of The Angels)', 'latitude': '34.092881', 'longitude': '-118.280382'} ,
      {'name': 'Mother Earths Farmacy', 'latitude': '33.782685', 'longitude': '-116.467137'} ,
      {'name': 'Mother Nature Remedy Caregivers', 'latitude': '34.166071', 'longitude': '-118.624032'} ,
      {'name': 'Mr Steal Your Patients $19 Cap', 'latitude': '33.96077', 'longitude': '-118.071098'} ,
      {'name': 'Mr. Nice Guy OC', 'latitude': '33.708513', 'longitude': '-117.855222'} ,
      {'name': 'Mr. T&#x27;s Collective PRE-ICO', 'latitude': '33.976316', 'longitude': '-118.330521'} ,
      {'name': 'MuV Dispensary - Phoenix', 'latitude': '33.601657', 'longitude': '-112.037625'} ,
      {'name': 'NACCC', 'latitude': '33.789777', 'longitude': '-118.308412'} ,
      {'name': 'Native RezSolutions', 'latitude': '33.917292', 'longitude': '-116.782906'} ,
      {'name': 'Natural Healing Center - NHC', 'latitude': '35.111033', 'longitude': '-120.61995'} ,
      {'name': 'Natural Organics', 'latitude': '33.869101', 'longitude': '-117.872942'} ,
      {'name': 'Natural Remedies Hollyweed', 'latitude': '34.08756', 'longitude': '-118.30925'} ,
      {'name': 'Natural Remedy Patient Center', 'latitude': '32.835505', 'longitude': '-109.724076'} ,
      {'name': 'Nature Med', 'latitude': '32.33772', 'longitude': '-111.079793'} ,
      {'name': 'Nature&#x27;s Medicines Fountain Hills', 'latitude': '33.596168', 'longitude': '-111.715123'} ,
      {'name': 'Nature&#x27;s Medicines Phoenix', 'latitude': '33.465405', 'longitude': '-112.112616'} ,
      {'name': 'Nature&#x27;s Therapy', 'latitude': '34.172819', 'longitude': '-118.562821'} ,
      {'name': 'Nature&#x27;s Wonder Dispensary', 'latitude': '33.415214', 'longitude': '-111.552672'} ,
      {'name': 'Nevada Made Marijuana', 'latitude': '35.161315', 'longitude': '-114.575154'} ,
      {'name': 'Nevada Made Marijuana - Henderson', 'latitude': '35.998055', 'longitude': '-115.124412'} ,
      {'name': 'Nevada Wellness Center', 'latitude': '36.131792', 'longitude': '-115.18978'} ,
      {'name': 'New Age Care Center', 'latitude': '34.010792', 'longitude': '-118.279622'} ,
      {'name': 'New Amsterdam Naturals', 'latitude': '34.028878', 'longitude': '-118.392697'} ,
      {'name': 'New Amsterdam Naturals', 'latitude': '34.028878', 'longitude': '-118.392697'} ,
      {'name': 'New Era Collective', 'latitude': '33.832724', 'longitude': '-117.977498'} ,
      {'name': 'New Generation', 'latitude': '33.708115', 'longitude': '-117.918637'} ,
      {'name': 'New Mexico Alternative Care', 'latitude': '36.728671', 'longitude': '-108.193805'} ,
      {'name': 'Next Level Dispensary', 'latitude': '33.924904', 'longitude': '-116.879045'} ,
      {'name': 'Nirvana Center - West Phoenix', 'latitude': '33.475777', 'longitude': '-112.222128'} ,
      {'name': 'NOHO CC : Pre-ICO', 'latitude': '34.217639', 'longitude': '-118.389845'} ,
      {'name': 'Noho Wellness Center', 'latitude': '34.160602', 'longitude': '-118.369982'} ,
      {'name': 'Nonstop 20 Spot', 'latitude': '33.918981', 'longitude': '-118.04726'} ,
      {'name': 'Normandie House of BOGO', 'latitude': '33.980797', 'longitude': '-118.300004'} ,
      {'name': 'NORMANDIE MEDICAL CENTER', 'latitude': '33.999466', 'longitude': '-118.300359'} ,
      {'name': 'Nova Dispensary', 'latitude': '33.4071', 'longitude': '-111.872623'} ,
      {'name': 'NuLeaf Las Vegas', 'latitude': '36.121483', 'longitude': '-115.153856'} ,
      {'name': 'Nurple Purps', 'latitude': '34.083268', 'longitude': '-118.302988'} ,
      {'name': 'NuWu Cannabis Marketplace', 'latitude': '36.184114', 'longitude': '-115.139213'} ,
      {'name': 'NuWu North', 'latitude': '36.357826', 'longitude': '-115.327984'} ,
      {'name': 'Oasis Cannabis | Las Vegas', 'latitude': '36.150582', 'longitude': '-115.160895'} ,
      {'name': 'Oasis Caregivers 20 Cap', 'latitude': '33.943633', 'longitude': '-118.295991'} ,
      {'name': 'Oasis North Dispensaries', 'latitude': '33.292589', 'longitude': '-111.970383'} ,
      {'name': 'Oasis South Dispensaries - Chandler', 'latitude': '33.205714', 'longitude': '-111.840708'} ,
      {'name': 'OC', 'latitude': '33.992226', 'longitude': '-117.890964'} ,
      {'name': 'OC3 Orange County Cannabis Club - Santa Ana', 'latitude': '33.705166', 'longitude': '-117.859997'} ,
      {'name': 'OC3 Orange County Cannabis Club - Santa Ana', 'latitude': '33.705166', 'longitude': '-117.859997'} ,
      {'name': 'Ocean Hye', 'latitude': '34.639186', 'longitude': '-120.445839'} ,
      {'name': 'OG Central 20 Cap', 'latitude': '33.977266', 'longitude': '-118.256157'} ,
      {'name': 'OG Compton 20 CAP', 'latitude': '33.889043', 'longitude': '-118.19484'} ,
      {'name': 'OG Express', 'latitude': '33.896229', 'longitude': '-118.32689'} ,
      {'name': 'OG Florence 25 Cap', 'latitude': '33.974786', 'longitude': '-118.306316'} ,
      {'name': 'OG FLOWERS', 'latitude': '34.097064', 'longitude': '-117.871911'} ,
      {'name': 'OG Maywood', 'latitude': '33.980571', 'longitude': '-118.172585'} ,
      {'name': 'OG Top Shop', 'latitude': '33.95718', 'longitude': '-118.29137'} ,
      {'name': 'Og Western 20 Cap', 'latitude': '33.962526', 'longitude': '-118.309406'} ,
      {'name': 'OGs &amp; OZs - 5G for $35', 'latitude': '34.017493', 'longitude': '-118.144407'} ,
      {'name': 'OGX The Hemp Co', 'latitude': '34.068193', 'longitude': '-117.244134'} ,
      {'name': 'Olive Tree Wellness Center', 'latitude': '33.047392', 'longitude': '-116.877955'} ,
      {'name': 'Olympic Best 20', 'latitude': '34.01934', 'longitude': '-118.200565'} ,
      {'name': 'OMG', 'latitude': '34.107148', 'longitude': '-117.34693'} ,
      {'name': 'OMG Avalon 20 Cap Collective', 'latitude': '33.965903', 'longitude': '-118.264877'} ,
      {'name': 'OMG GROUP', 'latitude': '34.3184215', 'longitude': '-118.4637737'} ,
      {'name': 'Omnia Industry', 'latitude': '33.981046', 'longitude': '-118.238455'} ,
      {'name': 'ONAC- White Sage', 'latitude': '34.164735', 'longitude': '-117.280506'} ,
      {'name': 'One Love Beach Club', 'latitude': '33.765794', 'longitude': '-118.159209'} ,
      {'name': 'One Stop Shop Collective', 'latitude': '34.033832', 'longitude': '-118.213736'} ,
      {'name': 'OPG - Ontana Planet Green', 'latitude': '34.840307', 'longitude': '-114.611007'} ,
      {'name': 'Orange County Flowers', 'latitude': '33.827683', 'longitude': '-117.959636'} ,
      {'name': 'Organic Greens Collective', 'latitude': '34.430941', 'longitude': '-119.829197'} ,
      {'name': 'Organic Health Solutions', 'latitude': '34.864816', 'longitude': '-118.154311'} ,
      {'name': 'Organic Solutions of the Desert', 'latitude': '33.8156407', 'longitude': '-116.4947532'} ,
      {'name': 'Organica Patient Group', 'latitude': '34.733087', 'longitude': '-112.454286'} ,
      {'name': 'OutCo - El Cajon', 'latitude': '32.824065', 'longitude': '-116.964689'} ,
      {'name': 'Oz n Bounce Drive-Thru', 'latitude': '34.067299', 'longitude': '-118.244262'} ,
      {'name': 'PAC House Of Top Shelf - 25 Cap', 'latitude': '34.303359', 'longitude': '-118.45635'} ,
      {'name': 'Pain Releaf', 'latitude': '33.365041', 'longitude': '-117.25003'} ,
      {'name': 'Palm Royale Collective', 'latitude': '33.782343', 'longitude': '-116.354324'} ,
      {'name': 'Palm Springs Safe Access (PSSA)', 'latitude': '33.806124', 'longitude': '-116.493391'} ,
      {'name': 'PAPAS &amp; BUD', 'latitude': '34.078948', 'longitude': '-117.886339'} ,
      {'name': 'Pasadena Flight Club 30 Cap', 'latitude': '34.169367', 'longitude': '-118.134417'} ,
      {'name': 'Patients and Caregivers', 'latitude': '34.1823539', 'longitude': '-118.370629'} ,
      {'name': 'Peoples OC', 'latitude': '33.71054', 'longitude': '-117.850524'} ,
      {'name': 'Phoenix Relief Center', 'latitude': '33.388534', 'longitude': '-112.133666'} ,
      {'name': 'Pico Gardens', 'latitude': '34.047393', 'longitude': '-118.299046'} ,
      {'name': 'Picos Finest Collective', 'latitude': '34.031804', 'longitude': '-118.251482'} ,
      {'name': 'Pineapple Club', 'latitude': '33.868257', 'longitude': '-118.383331'} ,
      {'name': 'Pisos - Flamingo &amp; S Maryland', 'latitude': '36.114328', 'longitude': '-115.13683'} ,
      {'name': 'Planet 13', 'latitude': '36.130225', 'longitude': '-115.176001'} ,
      {'name': 'Planet Buds', 'latitude': '33.827745', 'longitude': '-117.213474'} ,
      {'name': 'Planet Noho', 'latitude': '34.157323', 'longitude': '-118.412033'} ,
      {'name': 'Plantacea', 'latitude': '36.611948', 'longitude': '-121.844602'} ,
      {'name': 'PlantIt CBD', 'latitude': '34.072974', 'longitude': '-118.216077'} ,
      {'name': 'Platinum Club', 'latitude': '34.193901', 'longitude': '-118.534803'} ,
      {'name': 'PMG', 'latitude': '34.149861', 'longitude': '-118.125877'} ,
      {'name': 'Pomona Green Gardens', 'latitude': '34.05207', 'longitude': '-117.72278'} ,
      {'name': 'Pomonas Gas House', 'latitude': '34.057006', 'longitude': '-117.773783'} ,
      {'name': 'Pomonas Green', 'latitude': '34.0907', 'longitude': '-117.748405'} ,
      {'name': 'Ponderosa Releaf', 'latitude': '33.552681', 'longitude': '-112.258388'} ,
      {'name': 'Pot Father Collective', 'latitude': '33.94411', 'longitude': '-118.2737'} ,
      {'name': 'Power House', 'latitude': '33.941717', 'longitude': '-118.361714'} ,
      {'name': 'Private Reserve Co. - Whittier', 'latitude': '33.927194', 'longitude': '-118.028361'} ,
      {'name': 'Project Cannabis DTLA', 'latitude': '34.025814', 'longitude': '-118.241626'} ,
      {'name': 'Project Cannabis NoHo', 'latitude': '34.164791', 'longitude': '-118.367371'} ,
      {'name': 'PSA Organica', 'latitude': '33.812476', 'longitude': '-116.543626'} ,
      {'name': 'PURE CBD - CBD ONLY', 'latitude': '34.0908', 'longitude': '-118.36975'} ,
      {'name': 'PURE CBD - CBD ONLY', 'latitude': '34.09087', 'longitude': '-118.369943'} ,
      {'name': 'PureLife Alternative Wellness Center', 'latitude': '34.248401', 'longitude': '-118.586159'} ,
      {'name': 'Ramona Cannabis Company', 'latitude': '33.043355', 'longitude': '-116.904996'} ,
      {'name': 'Red Moon', 'latitude': '34.179103', 'longitude': '-118.44618'} ,
      {'name': 'Redwood Coast Dispensary', 'latitude': '37.094155', 'longitude': '-122.09591'} ,
      {'name': 'Reef Dispensaries - Las Vegas North', 'latitude': '36.21949', 'longitude': '-115.159893'} ,
      {'name': 'Reef Dispensaries - Phoenix', 'latitude': '33.460954', 'longitude': '-112.177268'} ,
      {'name': 'Reef Dispensaries - Queen Creek', 'latitude': '33.211796', 'longitude': '-111.68471'} ,
      {'name': 'Reef Dispensaries | Las Vegas Strip', 'latitude': '36.128261', 'longitude': '-115.17692'} ,
      {'name': 'Releaf Meds', 'latitude': '33.050341', 'longitude': '-116.871505'} ,
      {'name': 'REMEDISTA - CBD ONLY STORE', 'latitude': '34.165918', 'longitude': '-118.624429'} ,
      {'name': 'Remedy Inc.', 'latitude': '33.844645', 'longitude': '-116.459026'} ,
      {'name': 'Reseda Discount Caregivers', 'latitude': '34.179629', 'longitude': '-118.535003'} ,
      {'name': 'Retry Faith Church', 'latitude': '34.069963', 'longitude': '-117.128752'} ,
      {'name': 'Rite Greens Maywood', 'latitude': '33.988268', 'longitude': '-118.194802'} ,
      {'name': 'Riverside Meds', 'latitude': '33.962057', 'longitude': '-117.439712'} ,
      {'name': 'Rolling Greens CBD', 'latitude': '34.056832', 'longitude': '-117.681854'} ,
      {'name': 'Rolling Loud 15 Cap', 'latitude': '34.041274', 'longitude': '-118.169006'} ,
      {'name': 'Rose Buds 20 Cap', 'latitude': '34.048833', 'longitude': '-118.097478'} ,
      {'name': 'Rose Collective Pre-ICO', 'latitude': '33.9979881', 'longitude': '-118.4745905'} ,
      {'name': 'Rose Garden Collective', 'latitude': '33.964668', 'longitude': '-118.278606'} ,
      {'name': 'Rowland Cloud Club', 'latitude': '33.988597', 'longitude': '-117.90366'} ,
      {'name': 'ROWLAND TREES *GRAND OPENING*', 'latitude': '33.98728', 'longitude': '-117.884843'} ,
      {'name': 'Royal Flower', 'latitude': '34.002154', 'longitude': '-117.417946'} ,
      {'name': 'Royal Greens', 'latitude': '33.974454', 'longitude': '-118.301102'} ,
      {'name': 'Royal Greens', 'latitude': '34.041136', 'longitude': '-118.35028'} ,
      {'name': 'Royal Highness Cannabis Boutique', 'latitude': '33.720759', 'longitude': '-116.38508'} ,
      {'name': 'RX XCLUSIVE', 'latitude': '33.974445', 'longitude': '-118.249711'} ,
      {'name': 'SafePort Cannabis Dispensary', 'latitude': '34.176358', 'longitude': '-119.206948'} ,
      {'name': 'SafeWay Trees - North Hollywood', 'latitude': '34.17973', 'longitude': '-118.397505'} ,
      {'name': 'Sahara Wellness | Las Vegas', 'latitude': '36.143984', 'longitude': '-115.151414'} ,
      {'name': 'San BURNardino', 'latitude': '34.136289', 'longitude': '-117.290582'} ,
      {'name': 'San Diego Natural', 'latitude': '33.275738', 'longitude': '-117.147448'} ,
      {'name': 'Santa Barbara Care Center', 'latitude': '34.43573', 'longitude': '-119.826713'} ,
      {'name': 'Santa Cruz Mountain Herbs', 'latitude': '37.133318', 'longitude': '-121.98097'} ,
      {'name': 'Santa Cruz Naturals - Aptos', 'latitude': '36.975771', 'longitude': '-121.888112'} ,
      {'name': 'Santa Cruz Naturals - Watsonville', 'latitude': '36.904607', 'longitude': '-121.749181'} ,
      {'name': 'Save Greens', 'latitude': '34.013027', 'longitude': '-118.418316'} ,
      {'name': 'SCF', 'latitude': '33.81734', 'longitude': '-118.307436'} ,
      {'name': 'Scooters', 'latitude': '33.93178', 'longitude': '-118.29136'} ,
      {'name': 'SCSA South Coast Safe Access - Santa Ana 92705', 'latitude': '33.713018', 'longitude': '-117.841101'} ,
      {'name': 'SDRC', 'latitude': '32.764958', 'longitude': '-117.152504'} ,
      {'name': 'Secret Gardens 20 Cap', 'latitude': '33.998902', 'longitude': '-118.309167'} ,
      {'name': 'Secret Stash', 'latitude': '34.031516', 'longitude': '-118.152857'} ,
      {'name': 'SEMILLA HRC', 'latitude': '34.209269', 'longitude': '-118.363005'} ,
      {'name': 'Sespe Creek Collective', 'latitude': '34.442724', 'longitude': '-119.23795'} ,
      {'name': 'Sexy Nuggs Collective', 'latitude': '34.026861', 'longitude': '-118.237391'} ,
      {'name': 'SFVPC', 'latitude': '34.297559', 'longitude': '-118.457868'} ,
      {'name': 'Shango - LAS VEGAS', 'latitude': '36.12457', 'longitude': '-115.079134'} ,
      {'name': 'Shangri-La Care Centers Inc.', 'latitude': '34.443074', 'longitude': '-119.237926'} ,
      {'name': 'Shatter', 'latitude': '34.108543', 'longitude': '-117.290481'} ,
      {'name': 'SHIVAS DTLA PRE-ICO', 'latitude': '34.036765', 'longitude': '-118.263011'} ,
      {'name': 'Shoreline Underground', 'latitude': '34.018826', 'longitude': '-118.407696'} ,
      {'name': 'ShowGrow Las Vegas', 'latitude': '36.101184', 'longitude': '-115.296966'} ,
      {'name': 'ShowGrow Long Beach', 'latitude': '33.783171', 'longitude': '-118.151785'} ,
      {'name': 'Silver Sage Wellness', 'latitude': '36.159301', 'longitude': '-115.203838'} ,
      {'name': 'SkunkMasters', 'latitude': '34.17821', 'longitude': '-119.196292'} ,
      {'name': 'Sky Dispensaries – Ahwatukee', 'latitude': '33.3484875', 'longitude': '-111.9744219'} ,
      {'name': 'Sky Dispensaries – Mesa', 'latitude': '33.422448', 'longitude': '-111.718804'} ,
      {'name': 'Sky Dispensaries - Phoenix', 'latitude': '33.465546', 'longitude': '-112.013133'} ,
      {'name': 'SKY HIGH HOLISTIC NOHO', 'latitude': '34.181421', 'longitude': '-118.370136'} ,
      {'name': 'Slauson Super Clinic', 'latitude': '33.988713', 'longitude': '-118.338688'} ,
      {'name': 'Smart Collective', 'latitude': '34.152411', 'longitude': '-118.365146'} ,
      {'name': 'Smartweed', 'latitude': '34.08956', 'longitude': '-118.308888'} ,
      {'name': 'SO CAL PRIVATE RESERVE', 'latitude': '33.984571', 'longitude': '-118.291631'} ,
      {'name': 'So-Cal Co-op', 'latitude': '34.185259', 'longitude': '-118.622936'} ,
      {'name': 'SoCal', 'latitude': '33.18321', 'longitude': '-117.32707'} ,
      {'name': 'SoCal CBD', 'latitude': '33.513147', 'longitude': '-117.160213'} ,
      {'name': 'SOCC PRE-ICO', 'latitude': '34.175075', 'longitude': '-118.380492'} ,
      {'name': 'South Bay Church', 'latitude': '33.815972', 'longitude': '-118.296821'} ,
      {'name': 'South Bay Discount Budz', 'latitude': '32.59287', 'longitude': '-117.057567'} ,
      {'name': 'Southern Arizona Integrated Therapies', 'latitude': '32.220054', 'longitude': '-110.841607'} ,
      {'name': 'Starbuds', 'latitude': '34.076821', 'longitude': '-118.258472'} ,
      {'name': 'Stash Studio City', 'latitude': '34.14294', 'longitude': '-118.40413'} ,
      {'name': 'Sticky Saguaro', 'latitude': '33.2193', 'longitude': '-111.815927'} ,
      {'name': 'Stone Age Farmacy LA - Recreational', 'latitude': '33.9028761', 'longitude': '-118.2856391'} ,
      {'name': 'Stone Age Farmacy LB - Recreational', 'latitude': '33.819191', 'longitude': '-118.188958'} ,
      {'name': 'STR8 UP Collective', 'latitude': '34.028352', 'longitude': '-118.249581'} ,
      {'name': 'Straight Gas 20 Cap', 'latitude': '33.78016', 'longitude': '-118.263408'} ,
      {'name': 'STRAIGHT UP 20', 'latitude': '33.889416', 'longitude': '-118.192498'} ,
      {'name': 'Strain Balboa Caregivers - Adult Use', 'latitude': '34.2574809', 'longitude': '-118.6013195'} ,
      {'name': 'Strains Dispensary', 'latitude': '33.830703', 'longitude': '-117.244701'} ,
      {'name': 'STRAINZZZ on Slauson', 'latitude': '33.989121', 'longitude': '-118.251488'} ,
      {'name': 'SUGARBUDZ', 'latitude': '34.249553', 'longitude': '-118.403018'} ,
      {'name': 'SugarLeaf Powered by MedMen', 'latitude': '36.60898', 'longitude': '-121.846772'} ,
      {'name': 'Sun Valley Caregivers', 'latitude': '34.237405', 'longitude': '-118.370245'} ,
      {'name': 'SunGrow Collective &amp; CBD Center - Palm Springs', 'latitude': '33.924356', 'longitude': '-116.502298'} ,
      {'name': 'SUNSET HERBAL', 'latitude': '34.172486', 'longitude': '-118.38277'} ,
      {'name': 'Supa Nova Canoga', 'latitude': '34.200792', 'longitude': '-118.59917'} ,
      {'name': 'SUPER CLINIK - EAST SANTA ANA', 'latitude': '33.71305', 'longitude': '-117.871016'} ,
      {'name': 'Super Supreme', 'latitude': '34.057475', 'longitude': '-118.301898'} ,
      {'name': 'Supreme 20 Cap', 'latitude': '33.895777', 'longitude': '-118.214388'} ,
      {'name': 'Supreme Purity', 'latitude': '33.641357', 'longitude': '-117.918084'} ,
      {'name': 'Supreme Remedies', 'latitude': '33.814724', 'longitude': '-118.297784'} ,
      {'name': 'SWC Prescott', 'latitude': '34.554988', 'longitude': '-112.467299'} ,
      {'name': 'SWC Tempe', 'latitude': '33.425361', 'longitude': '-111.892233'} ,
      {'name': 'Sweet Flower - Melrose', 'latitude': '34.083988', 'longitude': '-118.367402'} ,
      {'name': 'Sweet Flower - Studio City', 'latitude': '34.141591', 'longitude': '-118.388004'} ,
      {'name': 'Sylmar Safe Access', 'latitude': '34.280936', 'longitude': '-118.402139'} ,
      {'name': 'TALLY CANNABIS', 'latitude': '34.10719', 'longitude': '-117.924785'} ,
      {'name': 'Temple of Purity', 'latitude': '33.666384', 'longitude': '-117.664165'} ,
      {'name': 'Territory Dispensary', 'latitude': '33.306041', 'longitude': '-111.966313'} ,
      {'name': 'That Loud 20 Cap Collective', 'latitude': '33.903111', 'longitude': '-118.233'} ,
      {'name': 'THC - The Healing Center', 'latitude': '34.848528', 'longitude': '-114.610832'} ,
      {'name': 'THCSD', 'latitude': '32.777733', 'longitude': '-117.115116'} ,
      {'name': 'The 15 Spot', 'latitude': '34.179086', 'longitude': '-118.461304'} ,
      {'name': 'The 420 Lounge', 'latitude': '33.833689', 'longitude': '-116.547197'} ,
      {'name': 'The Apothecarium', 'latitude': '36.142618', 'longitude': '-115.262656'} ,
      {'name': 'The Apothecary Shoppe - Las Vegas Strip', 'latitude': '36.115958', 'longitude': '-115.195802'} ,
      {'name': 'The Bern Center 6 Gram FTP', 'latitude': '34.136298', 'longitude': '-117.292614'} ,
      {'name': 'The Bud Farmacy', 'latitude': '34.8141494', 'longitude': '-114.6053557'} ,
      {'name': 'The Cannabliss', 'latitude': '35.42098', 'longitude': '-119.02155'} ,
      {'name': 'The Circle', 'latitude': '33.789194', 'longitude': '-118.139375'} ,
      {'name': 'the corner bakery', 'latitude': '33.9322', 'longitude': '-118.27893'} ,
      {'name': 'The Coughy Shop', 'latitude': '33.9751738', 'longitude': '-116.5288339'} ,
      {'name': 'The Cure Collective', 'latitude': '34.012077', 'longitude': '-118.277422'} ,
      {'name': 'The Dank Gardens', 'latitude': '33.935067', 'longitude': '-118.360822'} ,
      {'name': 'The Dispensary NV - Henderson', 'latitude': '36.034435', 'longitude': '-115.028171'} ,
      {'name': 'The Dispensary NV - West Las Vegas', 'latitude': '36.092533', 'longitude': '-115.208505'} ,
      {'name': 'The Downtown Dispensary', 'latitude': '32.228086', 'longitude': '-110.96811'} ,
      {'name': 'The Dub Club', 'latitude': '34.186264', 'longitude': '-118.449114'} ,
      {'name': 'The Farmacy Westwood', 'latitude': '34.061137', 'longitude': '-118.447467'} ,
      {'name': 'THE FIRE ESCAPE', 'latitude': '33.93343', 'longitude': '-118.343568'} ,
      {'name': 'The Flower House', 'latitude': '33.1825738', 'longitude': '-117.2175966'} ,
      {'name': 'The Garden Collective', 'latitude': '33.92532', 'longitude': '-118.2737'} ,
      {'name': 'The Good Dispensary', 'latitude': '33.408746', 'longitude': '-111.871404'} ,
      {'name': 'The Goodlife', 'latitude': '34.03816', 'longitude': '-117.949458'} ,
      {'name': 'The Green Earth Farmacie', 'latitude': '34.212052', 'longitude': '-118.462523'} ,
      {'name': 'The Green Easy', 'latitude': '34.076304', 'longitude': '-118.370479'} ,
      {'name': 'The Green Halo - Tucson Dispensary', 'latitude': '32.112559', 'longitude': '-110.858661'} ,
      {'name': 'The Green Leaf', 'latitude': '33.748167', 'longitude': '-116.884417'} ,
      {'name': 'The Grove', 'latitude': '36.21898', 'longitude': '-115.988331'} ,
      {'name': 'The Grove', 'latitude': '36.105245', 'longitude': '-115.150279'} ,
      {'name': 'The Grove', 'latitude': '32.771557', 'longitude': '-117.021639'} ,
      {'name': 'The Healing Center - THC', 'latitude': '34.848976', 'longitude': '-114.6108'} ,
      {'name': 'The Healing Hut', 'latitude': '33.747531', 'longitude': '-116.940394'} ,
      {'name': 'THE HEALING TOUCH', 'latitude': '34.16488', 'longitude': '-118.525072'} ,
      {'name': 'The Herbal Center', 'latitude': '33.622113', 'longitude': '-117.724908'} ,
      {'name': 'The High Note - Culver City', 'latitude': '34.025836', 'longitude': '-118.360725'} ,
      {'name': 'The High Note - East LA', 'latitude': '34.074718', 'longitude': '-118.166772'} ,
      {'name': 'The High Way', 'latitude': '33.985236', 'longitude': '-118.057218'} ,
      {'name': 'The Higher Path', 'latitude': '34.1493088', 'longitude': '-118.4398272'} ,
      {'name': 'The Holistic Center', 'latitude': '33.677546', 'longitude': '-112.029184'} ,
      {'name': 'The Hotbox In Hawthorne', 'latitude': '33.907083', 'longitude': '-118.361459'} ,
      {'name': 'The Jackpot', 'latitude': '34.014908', 'longitude': '-118.132041'} ,
      {'name': 'The Joint', 'latitude': '34.029714', 'longitude': '-117.968229'} ,
      {'name': 'The JOINT', 'latitude': '33.723269', 'longitude': '-117.85249'} ,
      {'name': 'The Kind Center, Inc.', 'latitude': '34.10657', 'longitude': '-118.330486'} ,
      {'name': 'THE LAB', 'latitude': '33.930398', 'longitude': '-117.995208'} ,
      {'name': 'The Leaf El Paseo', 'latitude': '33.720072', 'longitude': '-116.378257'} ,
      {'name': 'The Lift', 'latitude': '33.771492', 'longitude': '-118.158722'} ,
      {'name': 'The Lit Corner', 'latitude': '33.992049', 'longitude': '-118.067923'} ,
      {'name': 'The Marijuana Factory', 'latitude': '34.237233', 'longitude': '-118.596722'} ,
      {'name': 'The Medicine Room', 'latitude': '35.034508', 'longitude': '-110.719677'} ,
      {'name': 'The Medicine Woman', 'latitude': '33.869246', 'longitude': '-118.140599'} ,
      {'name': 'The Micro Buddery', 'latitude': '33.961199', 'longitude': '-116.504554'} ,
      {'name': 'The Mint Dispensary', 'latitude': '33.377176', 'longitude': '-111.963797'} ,
      {'name': 'The Nature of Taoism', 'latitude': '33.788055', 'longitude': '-117.836546'} ,
      {'name': 'The Nirvana Center', 'latitude': '33.448328', 'longitude': '-112.135104'} ,
      {'name': 'The Nirvana Center - Apache Juction', 'latitude': '33.414719', 'longitude': '-111.567558'} ,
      {'name': 'The Nirvana Center of Prescott Valley', 'latitude': '34.581797', 'longitude': '-112.354776'} ,
      {'name': 'The OG Collective - WORLDS LARGEST MENU!', 'latitude': '33.783074', 'longitude': '-116.469275'} ,
      {'name': 'The Originals Factory &amp; Weed Shop', 'latitude': '33.98087', 'longitude': '-118.26285'} ,
      {'name': 'The Other Side', 'latitude': '33.831635', 'longitude': '-117.992368'} ,
      {'name': 'The Phoenix', 'latitude': '33.44298', 'longitude': '-112.233214'} ,
      {'name': 'The Plant', 'latitude': '34.183078', 'longitude': '-118.60294'} ,
      {'name': 'The Plug', 'latitude': '34.128573', 'longitude': '-117.291558'} ,
      {'name': 'The Plug 20 Cap Collective', 'latitude': '33.974321', 'longitude': '-118.316794'} ,
      {'name': 'The Plug Co-Op - Grand Opening!!', 'latitude': '34.224614', 'longitude': '-118.379601'} ,
      {'name': 'The Pot Spot', 'latitude': '33.782585', 'longitude': '-117.222607'} ,
      {'name': 'The Pottery - Medical', 'latitude': '34.04513', 'longitude': '-118.348584'} ,
      {'name': 'The Pottery - Recreational', 'latitude': '34.04513', 'longitude': '-118.348584'} ,
      {'name': 'The Pottery Barn', 'latitude': '34.136368', 'longitude': '-117.336548'} ,
      {'name': 'THE PRESIDENTIAL COLLECTIVE', 'latitude': '33.96511', 'longitude': '-118.309234'} ,
      {'name': 'The Prime Leaf', 'latitude': '32.235915', 'longitude': '-110.902564'} ,
      {'name': 'The Reef', 'latitude': '36.616939', 'longitude': '-121.841194'} ,
      {'name': 'The Reefinery - Adult Use', 'latitude': '34.179607', 'longitude': '-118.457681'} ,
      {'name': 'The Reefinery - Medical', 'latitude': '34.179607', 'longitude': '-118.457681'} ,
      {'name': 'The Relief Collective', 'latitude': '34.051079', 'longitude': '-118.365329'} ,
      {'name': 'The Shop WH', 'latitude': '34.166414', 'longitude': '-118.592936'} ,
      {'name': 'The Sky Box', 'latitude': '33.708165', 'longitude': '-117.996695'} ,
      {'name': 'The Smoke Shop', 'latitude': '34.200434', 'longitude': '-118.601775'} ,
      {'name': 'The Source', 'latitude': '36.143385', 'longitude': '-115.241977'} ,
      {'name': 'The Source - Henderson', 'latitude': '36.017374', 'longitude': '-115.117176'} ,
      {'name': 'The Spot', 'latitude': '33.703512', 'longitude': '-117.860909'} ,
      {'name': 'The Station', 'latitude': '33.792854', 'longitude': '-118.194048'} ,
      {'name': 'The Superior Dispensary', 'latitude': '33.445753', 'longitude': '-112.182583'} ,
      {'name': 'The Vine 6 Gram FTP', 'latitude': '33.843701', 'longitude': '-117.942337'} ,
      {'name': 'Therapeutic Healthcare Collective', 'latitude': '36.988348', 'longitude': '-121.952912'} ,
      {'name': 'Therapeutic Solutions &amp; Alternatives', 'latitude': '33.761207', 'longitude': '-118.078918'} ,
      {'name': 'THRIVE Cannabis Marketplace', 'latitude': '36.216991', 'longitude': '-115.177958'} ,
      {'name': 'THRIVE Cannabis Marketplace Downtown', 'latitude': '36.158094', 'longitude': '-115.15642'} ,
      {'name': 'TLC', 'latitude': '34.01861', 'longitude': '-118.1992'} ,
      {'name': 'TMC The Members Club', 'latitude': '34.04195', 'longitude': '-118.25786'} ,
      {'name': 'TNT Green', 'latitude': '34.253362', 'longitude': '-118.296504'} ,
      {'name': 'Top Notch THC', 'latitude': '36.087247', 'longitude': '-115.045508'} ,
      {'name': 'Torrance Healing Church', 'latitude': '33.831797', 'longitude': '-118.300463'} ,
      {'name': 'Torrey Holistics', 'latitude': '32.898103', 'longitude': '-117.222038'} ,
      {'name': 'Total Accountability Patient Care - Benson', 'latitude': '31.967778', 'longitude': '-110.295596'} ,
      {'name': 'Tree Haus', 'latitude': '34.023963', 'longitude': '-118.173326'} ,
      {'name': 'TreeHouse', 'latitude': '36.98746', 'longitude': '-121.967734'} ,
      {'name': 'TreeHouse Collective - SGV', 'latitude': '34.029493', 'longitude': '-117.967616'} ,
      {'name': 'Trees R Us', 'latitude': '34.109974', 'longitude': '-118.014105'} ,
      {'name': 'TREETOP LA', 'latitude': '34.075423', 'longitude': '-118.221583'} ,
      {'name': 'Trichome Research Group (TRG)', 'latitude': '33.94176', 'longitude': '-118.26534'} ,
      {'name': 'Tru Bliss', 'latitude': '33.340414', 'longitude': '-111.684114'} ,
      {'name': 'True 20', 'latitude': '33.99226', 'longitude': '-118.278609'} ,
      {'name': 'TruMed Premier Dispensary', 'latitude': '33.466228', 'longitude': '-111.995253'} ,
      {'name': 'Tulare Alternative Health Care', 'latitude': '36.210708', 'longitude': '-119.345166'} ,
      {'name': 'TVC', 'latitude': '34.861009', 'longitude': '-118.164365'} ,
      {'name': 'Ultra Health - Farmington', 'latitude': '36.760182', 'longitude': '-108.155292'} ,
      {'name': 'UNDEFEATED 25', 'latitude': '34.144443', 'longitude': '-118.126922'} ,
      {'name': 'Unified Patient Alliance', 'latitude': '34.223115', 'longitude': '-118.387437'} ,
      {'name': 'United Growers Inc.', 'latitude': '36.327435', 'longitude': '-119.221524'} ,
      {'name': 'UNITED HEALTH CARE CENTER (UHCC)', 'latitude': '33.987423', 'longitude': '-118.238073'} ,
      {'name': 'Unity Church of Cannabis', 'latitude': '33.863864', 'longitude': '-117.90844'} ,
      {'name': 'Univerde One Love', 'latitude': '34.105774', 'longitude': '-117.847207'} ,
      {'name': 'Universal Collective - Adult Use', 'latitude': '34.134369', 'longitude': '-118.360284'} ,
      {'name': 'University 20 CAP', 'latitude': '34.020447', 'longitude': '-118.275478'} ,
      {'name': 'Urban Greenhouse Dispensary', 'latitude': '33.495436', 'longitude': '-112.115594'} ,
      {'name': 'Urban Greens Collective', 'latitude': '33.854653', 'longitude': '-117.844655'} ,
      {'name': 'Urban Society Club', 'latitude': '34.023798', 'longitude': '-118.1824'} ,
      {'name': 'Urban Treez - Adult Use', 'latitude': '34.139913', 'longitude': '-118.378285'} ,
      {'name': 'Urbn Leaf - Bay Park', 'latitude': '32.767439', 'longitude': '-117.202268'} ,
      {'name': 'Urbn Leaf - Bay Park', 'latitude': '32.767439', 'longitude': '-117.202268'} ,
      {'name': 'Urbn Leaf - Seaside', 'latitude': '36.609019', 'longitude': '-121.849419'} ,
      {'name': 'Urbn Leaf Southwest', 'latitude': '32.546403', 'longitude': '-117.033342'} ,
      {'name': 'Valley Herbal Center', 'latitude': '34.186467', 'longitude': '-118.449643'} ,
      {'name': 'Valley of the Sun Medical Dispensary', 'latitude': '33.409171', 'longitude': '-112.406822'} ,
      {'name': 'Valley Pure', 'latitude': '36.414482', 'longitude': '-119.098749'} ,
      {'name': 'Van Nuys Greens', 'latitude': '34.182573', 'longitude': '-118.44897'} ,
      {'name': 'Venice Medical Center', 'latitude': '34.024321', 'longitude': '-118.396509'} ,
      {'name': 'Vermont 30 Cap', 'latitude': '33.955963', 'longitude': '-118.29127'} ,
      {'name': 'VIP Collective', 'latitude': '33.965219', 'longitude': '-118.309159'} ,
      {'name': 'Vista Patient Care', 'latitude': '33.17462', 'longitude': '-117.210742'} ,
      {'name': 'Wake n&#x27; Bake', 'latitude': '35.394765', 'longitude': '-118.993724'} ,
      {'name': 'WAM - El Mirage', 'latitude': '33.609532', 'longitude': '-112.329431'} ,
      {'name': 'WEED - Wellness Earth Energy Dispensary Powered by Project Cannabis', 'latitude': '34.139865', 'longitude': '-118.38341'} ,
      {'name': 'Weed 4 Less 15 Cap', 'latitude': '33.972033', 'longitude': '-118.273791'} ,
      {'name': 'Weed Warehouse', 'latitude': '33.612346', 'longitude': '-117.713484'} ,
      {'name': 'Weedier', 'latitude': '33.9379', 'longitude': '-118.043517'} ,
      {'name': 'Weedland Hills', 'latitude': '34.168379', 'longitude': '-118.609931'} ,
      {'name': 'Weedmart 30 Cap', 'latitude': '34.11051', 'longitude': '-118.006864'} ,
      {'name': 'WeedWay', 'latitude': '34.24807', 'longitude': '-118.28437'} ,
      {'name': 'West Cliff Wellness', 'latitude': '36.959542', 'longitude': '-122.045655'} ,
      {'name': 'WEST CLINIK - SANTA ANA', 'latitude': '33.718931', 'longitude': '-117.916709'} ,
      {'name': 'West Coast Cannabis Club', 'latitude': '33.816203', 'longitude': '-116.46135'} ,
      {'name': 'West Coast Cannabis Club', 'latitude': '33.72089', 'longitude': '-116.365757'} ,
      {'name': 'West Coast Collective', 'latitude': '33.992731', 'longitude': '-118.300032'} ,
      {'name': 'West Coast Collective', 'latitude': '34.034181', 'longitude': '-117.975923'} ,
      {'name': 'West Coast Holistic Center', 'latitude': '34.257104', 'longitude': '-118.469481'} ,
      {'name': 'West Valley Patients Center (PRE-ICO)', 'latitude': '34.1644899', 'longitude': '-118.6282757'} ,
      {'name': 'Western Top Shop 20 CAP', 'latitude': '33.976954', 'longitude': '-118.308427'} ,
      {'name': 'Westpoint Medicinal', 'latitude': '34.106355', 'longitude': '-117.853974'} ,
      {'name': 'WESTSiDE CLLCTV', 'latitude': '33.853821', 'longitude': '-117.245437'} ,
      {'name': 'Westside Meds', 'latitude': '34.03008', 'longitude': '-118.389652'} ,
      {'name': 'Wheelhouse Cannabis Dispensary', 'latitude': '34.176177', 'longitude': '-119.212429'} ,
      {'name': 'White Castle', 'latitude': '33.790018', 'longitude': '-118.276013'} ,
      {'name': 'White Mountain Health Center', 'latitude': '33.638702', 'longitude': '-112.26338'} ,
      {'name': 'Whittier Daily Greens', 'latitude': '33.990054', 'longitude': '-118.06475'} ,
      {'name': 'Whittier Hot Box Collective', 'latitude': '33.917286', 'longitude': '-118.048972'} ,
      {'name': 'Whittier TopShop', 'latitude': '33.925052', 'longitude': '-118.021303'} ,
      {'name': 'Whittier Treehouse', 'latitude': '33.990579', 'longitude': '-118.065524'} ,
      {'name': 'Whittier Wonders', 'latitude': '33.918954', 'longitude': '-118.047254'} ,
      {'name': 'WHTC - Adult Use', 'latitude': '34.136431', 'longitude': '-118.362129'} ,
      {'name': 'Woke CBD', 'latitude': '33.908683', 'longitude': '-117.468452'} ,
      {'name': 'WOW 25 CAP', 'latitude': '33.782501', 'longitude': '-118.233304'} ,
      {'name': 'Yavapai Herbal - Cottonwood', 'latitude': '34.728786', 'longitude': '-112.021255'} ,
      {'name': 'YiLo Superstore', 'latitude': '33.610077', 'longitude': '-112.119882'} ,
      {'name': 'Zen Dispensary', 'latitude': '33.908601', 'longitude': '-112.141528'} ,
      {'name': 'Zen Healing West Hollywood', 'latitude': '34.089769', 'longitude': '-118.375453'} ,
      {'name': 'Zen Leaf Las Vegas', 'latitude': '36.074477', 'longitude': '-115.29127'} ,
      {'name': 'Zen Noho pre-ICO', 'latitude': '34.1638419', 'longitude': '-118.3700182'}]
    }
  }

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return <Marker key={index} id={index} position={{
       lat: store.latitude,
       lng: store.longitude
     }}
     onClick={() => alert(store.name)} />
    })
  }

  render() {
    return (
        <Map
          google={this.props.google}
          zoom={8}
          style={mapStyles}
          initialCenter={{ lat: 33.643557, lng: -117.667692}}
        >
          {this.displayMarkers()}
        </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBBlYWAbzJKj6XSSFa0OKcemajqESRlbhE'
})(MapContainer);